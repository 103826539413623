.footer {
	padding: 60px;
	background: $grey-dark;

	.logo-footer {
		width: 140px;
		margin: 0 auto 48px;
	}

	.nav-social {
		display: flex;
		justify-content: space-between;
		width: 120px;
		margin: auto;

		.icon {
			width: 42px;
			height: 42px;
		}
	}

	&_us {
		padding:20px;
	    text-align: center;
	    border-top: 1px $blue solid;
	    margin: 0;

	    a {
	    	color: $blue;
	    	transition: all .3s ease;

	    	&:hover {
	    		color: $blue;
	    	}
	    }
	}

	@include breakpoint(tablet) {
		padding: 90px 0;
	
		.logo-footer {
			width: 140px;
			margin: 0 auto 36px;
		}
	
		.nav-social {
			width: 100px;
	
			.icon {
				width: 28px;
				height: 28px;
			}
		}
	}

	@include breakpoint(laptop) {
		padding: 60px;

		.address {
			margin: 0;

			a {
				background-size: contain;
				padding: 8px 0 8px 52px;
				transition: all .3s ease;

				&:hover {

					&.phone {
						color: red;
					}

					&.mail {
						color: red;
					}
				}
			}
		}

		.icon {
			transition: all .3s ease;

			&:hover {
				fill: $blue;
			}
		}
	}
} 