.history {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../img/bg-history-m.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center; 
    padding: 60px 20px;  
    color: $white;

    h2 {
        font-size: 22px;
		letter-spacing: 8px;
        margin-bottom: 26px;
        
		span {
			font-size: 42px;
            display: block;
		}
    }

    @include breakpoint(phablet) {
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../img/bg-history.jpg");

    }

    @include breakpoint(tablet) {
        background-image: linear-gradient(to right, rgba(0, 0, 0, .6) 40%, rgba(0, 0, 0, 0) 70%), url("../img/bg-history.jpg");
        display: flex;
        height: 70vh;
        padding: 0 90px;        

        article {
            max-width: 1180px;
            margin: auto; 
            padding-right: 60%;
        }
    }
    
	@include breakpoint(laptop) {
		
		h2 {
			font-size: 28px;
			letter-spacing: 16px;

			span {
				font-size: 56px;
			}
		}

        article {
            padding-right: 50%;
        }        
	}
}

.us { 
    background-color: $black; 
    color: $grey-light;
    padding: 60px 20px;
    text-align: center;

    h2 {
        font-size: 22px;
        background: url('../img/bg-us-h2.png') no-repeat center top;
        background-size: 38px;
        padding-top: 42px;
        color: $gold;
        margin-bottom: 18px;
        letter-spacing: 8px;        
    }

    p {
        max-width: 660px;
        margin: 0 auto 12px;

        &:last-child {
            margin: 0 auto;
        }
    }
  
    .swiper-container-us {
        position: relative;
        width: 70%;
        margin: 0 auto 42px;
        
        .swiper {
            overflow: hidden;
        } 
        
        picture {
        
            img {
                transition: 0.2s linear;
                cursor: pointer;
            }
        
            &:hover img {
                transform: scale(1.05);
            }
        } 
 
        .swiper-button {

            &:after {
                content: none;
            }
            &-prev{
                left: -40px;

                svg {
                    transform: rotate(.5turn);
                } 
            }
            &-next{
                right: -40px;
            }
        }
    }

    .popup-image {
        position: fixed;
        background-color: rgba(0, 0, 0, .9);
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 100;
        display: none;
        
        span {
            position: absolute;
            top: 10px;
            right: 20px;
            font-size: 60px;
            font-weight: 800;
            color: #fff;
            cursor: pointer;
            z-index: inherit;
        } 
        
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 5px solid #fff;
            border-radius: 5px;
            width: 90%;
            object-fit: cover;
        }    
    }

    &.press {
        
        .swiper-container-us {
            margin: 42px auto; 

            span {
                display: block;
                color: white;
                line-height: 1.4;
                margin-top: 12px;
            }

            &:hover img {
                transform: none;
            }            
        }
    }

    @include breakpoint(tablet) {
        padding: 0 90px 90px;
        background: none;
        position: relative;
        
        &:before {
            content: '';
            background-color: $black;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 42px;
            left: 0;
            z-index: -1;
        }

        .swiper-container-us {
            margin-top: -42px;
            max-width: 1180px;
            width: 100%;

            .swiper-button {

                svg {
                    width: 22px;
                }
            } 
        }

        &.press {
            padding: 90px 110px 90px;
            background: $black;

            &:before { 
                content: none; 
            }

            picture {
                position: relative;
                
                span {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    text-align: left;
                    padding: 0 12px 12px;            
                }

                &:before {
                    content: '';
                    background: linear-gradient(to top, rgba(0, 0, 0, .9) 20%, rgba(0, 0, 0, 0));
                    position: absolute;
                    bottom: 0;
                    height: 60%;
                    z-index: 0;
                    left: 0;
                    width: 100%;
                    pointer-events: none;
                }
            }

            .swiper-container-us {
                margin: 68px auto 0;
            }
        }
    }

	@include breakpoint(laptop) {
		
		h2 {
			font-size: 34px;
		}       

        .swiper-container-us {
            margin-bottom: 82px;   
        }

        .popup-image img {
            width: 1024px;
        }        

        &.press {

            picture {
                
                span {
                    padding: 0 26px 20px;            
                }   
            }
        }     
	}
}

.product { 
    padding: 60px 20px;
    background: url('../img/bg-motorhomes-m.jpg') no-repeat center;
    background-size: cover;
    background-image: linear-gradient(to right, rgba(0, 0, 0, .6) 40%, rgba(0, 0, 0, 0.6) 70%), url('../img/bg-motorhomes-m.jpg') ;
    color: $white;
    
    h2 {
        color: $gold;
        margin-bottom: 36px;
        letter-spacing: 8px;        
    }

    .button {
        margin-top: 42px;
    }

    &.outlander {
        background-image: linear-gradient(to right, rgba(0, 0, 0, .6) 40%, rgba(0, 0, 0, 0.6) 70%), url('../img/bg-outlander-m.jpg');

    }

    @include breakpoint(phablet) {
        background-image: linear-gradient(to right, rgba(0, 0, 0, .6) 40%, rgba(0, 0, 0, 0.6) 70%), url('../img/bg-motorhomes.jpg');
        
        &.outlander {
            background-image: linear-gradient(to right, rgba(0, 0, 0, .6) 40%, rgba(0, 0, 0, 0.6) 70%), url('../img/bg-outlander.jpg');
            background-position: left;
        }
    }
    @include breakpoint(tablet) {        
        display: flex;
        height: 70vh;
        padding: 0 90px;  

        article {
            max-width: 1180px;
            margin: auto; 
            padding-right: 60%;
        }
        
        &.outlander {
            
            article {
                padding: 0 0 0 60%;
            }
        }
    }
    @include breakpoint(laptop) {
        height: 85vh;

        article {
            padding-right: 50%;
        }
        
        &.outlander {
            
            article {
                padding: 0 0 0 50%;
            }
        }
    }
}

.models {
    padding: 60px 20px;
    color: $blue-dark;
    
    h2 {
        margin-bottom: 86px;
        letter-spacing: 8px;
        padding-bottom: 32px;
        position: relative; 
        text-align: center;      

        &:before {
            content: '';
            width: 90px;
            height: 1px;
            background-color: $blue-dark;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50px);
        }
    }

    h3 {
        font-size: 26px;
        text-align: center;
        margin: 64px 0 18px 0;

        span { 
            display: block;
        }
    }

    .items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 64px;

        li {
            margin-bottom: 12px;
        }
    }

    .specifications-list {
        display: flex;
        flex-flow: column;
        align-items: center;

        li {
            position: relative;
            font-weight: 600;
            font-size: 14px;
            color: #666;
            margin-bottom: 15px;
            word-wrap: break-word;
            max-width: 70%;

            &:before {
                position: absolute;
                content: '';
                background-color: #666;
                width: 3px;
                height: 3px;
                left: -15px;
                top: 37%;
                border-radius: 50%;
            }
        }
    }

    .button {
        margin-top: 58px;
        color: $blue-dark;
        border-color: $blue-dark;
        width: 100%;

        & > span {
            color: $blue-dark;
        }

        &:before {
            color: $white;
            background: $blue-dark;
        }
    }

    @include breakpoint(phablet) {
        
        .items {
            grid-template-columns: repeat(4, 1fr);
            gap: 30px;
            margin: 0;

            li {
                margin-bottom: 0;
            }
        }
    }
    
    @include breakpoint(tablet) {
        padding: 90px; 
        max-width: 1180px;
        margin: auto; 

        .specifications-list li {
            margin-bottom: 10px;
            max-width: 100%;
        }        
    } 
    
    @include breakpoint(laptop) {
        padding: 90px 0; 
    }
}

.reels {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../img/bg-reels-m.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center; 
    padding: 60px 20px;  
    color: $white;

    h2 {
        color: rgba(255, 255, 255, 0.6);
        font-size: 22px;
		letter-spacing: 8px;
        margin-bottom: 26px;
        text-align: center;
        
		span {
			font-size: 42px;
            color: $gold;
            display: block;
		}
    }
  
    .swiper-container-reels {
        position: relative;
        width: 70%;
        margin: 0 auto 42px;
        
        .swiper {
            overflow: hidden;
        } 

        .swiper-button {
            width: 38px;
            height: 38px;

            &:after {
                content: none;
            }
            svg {
                width: 100%;
                transition: all .3s ease;
                transform: scale(1);

                &:hover {
                    transform: scale(1.1);
                }
            }
            &-prev{
                left: -50px;

                svg {
                    transform: rotate(.5turn);
                    
                    &:hover {
                        transform: scale(1.1) rotate(.5turn);
                    }
                } 
            }
            &-next{
                right: -50px;
            }
        }

        a {
            // background: rgba(204,204,204,.3);
            position: absolute;
            z-index: 3;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
        }

        .play {
            width: 100px;
            margin: auto;
            transition: all .2s ease-out ;
        }
    }    

    @include breakpoint(phablet) {
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../img/bg-reels.jpg");

    }

    @include breakpoint(tablet) {
        background-image: linear-gradient(to right, rgba(0, 0, 0, .6) 40%, rgba(0, 0, 0, 0) 70%), url("../img/bg-reels.jpg");
        padding: 90px;        
        
        .swiper-container-reels {
            max-width: 1180px;
            margin: auto; 
            width: 100%;

            .swiper-button {
    
                &-prev{
                    left: -70px;
                }
                &-next{
                    right: -70px;
                }
            }            
        }

        a:hover {
            .play {
                width: 110px;
            }
        }
    }

    @include breakpoint(desktop) {
        background-image: linear-gradient(to right, rgba(0, 0, 0, .6) 40%, rgba(0, 0, 0, 0) 70%), url("../img/bg-reels-h.jpg");
        padding: 100px;  
   
        h2 {
            font-size: 28px;
            letter-spacing: 16px;
            margin-bottom: 38px;
            
            span {
                font-size: 54px;
            }
        }        
    }      
}

.feed {
    padding: 60px 20px;  
    color: $blue-dark;
    // position: relative;
    z-index: 10;

    h2 {
        font-size: 26px;
        margin-bottom: 26px;
        text-align: center;
    }

    // .button {
    //     color: $blue-dark;
    //     border-color: $blue-dark;
    //     width: 190px;
    //     margin: 48px auto 0;

    //     & > span {
    //         color: $blue-dark;
    //     }

    //     &:before {
    //         color: $white;
    //         background: $blue-dark;
    //     }
    // }
     
    // .items {
        
    //     li {
    //         margin-bottom: 20px;
    //     }
    // }

    // &:after {
    //     content: "";
    //     width: 100%;
    //     height: 120px;
    //     position: absolute;
    //     bottom: 0;
    //     left: 0; 
    //     background: $white;
    //     z-index: 99999;
    // }

    @include breakpoint(phablet) {
        
        .items {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 6px;
            
            li {
                margin-bottom: 0;
            } 
        }
    }
    
    @include breakpoint(tablet) {
        padding: 90px 0 60px 0; 
        // max-width: 960px;
        margin: auto; 
    } 
    
    @include breakpoint(laptop) {
        // padding: 90px 0; 

        // .items {
            
        //     li {    
        //         overflow: hidden;
                    
        //         img {
        //             transform: scale(1);
        //             transition: all .3s ease-in;
        //         }

        //         &:hover {
                    
        //             img {
        //                 transform: scale(1.05);
        //             }
        //         }
        //     } 
        // }        
    }
}

.dreams {
    background-image: url("../img/bg-dreams-m.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center; 
    padding: 60px 20px;  
    color: $white;

    h2 {
        color: rgba(255, 255, 255, 0.6);
        font-size: 22px;
		letter-spacing: 8px;
        margin-bottom: 26px;
        text-align: center;
        
		span {
			font-size: 42px;
            color: $gold;
            display: block;
		}
    }

    picture {
        margin-bottom: 42px;
    }

    p {
        margin-bottom: 26px;

        &:last-child {
            margin: 0;
        }

        strong {
            color: $gold;
            font-family: $font-title;
            font-size: 20px;
        }
    }
  
    .swiper-container-dreams {
        position: relative;
        width: 70%;
        margin: 0 auto;
        
        .swiper {
            overflow: hidden;
        } 

        .swiper-button {
            width: 38px;
            height: 38px;

            &:after {
                content: none;
            }
            svg {
                width: 100%;
                transition: all .3s ease;
                transform: scale(1);

                &:hover {
                    transform: scale(1.1);
                }
            }
            &-prev{
                left: -50px;

                svg {
                    transform: rotate(.5turn);
                    
                    &:hover {
                        transform: scale(1.1) rotate(.5turn);
                    }
                } 
            }
            &-next{
                right: -50px;
            }
        }
    }    

    @include breakpoint(phablet) {
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../img/bg-dreams.jpg");

        .swiper-slide {
            display: grid;
            grid-template-columns: 40% auto;
            gap: 30px;
            align-items: center;
        }
        
        picture {
            margin: 0;
        }
    }

    @include breakpoint(tablet) {
        background-image: linear-gradient(to right, rgba(0, 0, 0, .6) 40%, rgba(0, 0, 0, 0) 70%), url("../img/bg-dreams.jpg");
        padding: 90px;        
        
        .swiper-container-dreams {
            max-width: 960px;
            margin: auto; 
            width: 80%;

            .swiper-button {
    
                &-prev{
                    left: -70px;
                }
                &-next{
                    right: -70px;
                }
            }            
        }

        .swiper-slide {
            gap: 60px;
        }        
    }

    @include breakpoint(desktop) {
        background-image: linear-gradient(to right, rgba(0, 0, 0, .6) 40%, rgba(0, 0, 0, 0) 70%), url("../img/bg-dreams-h.jpg");
        padding: 100px;  

        h2 {
            font-size: 28px;
            letter-spacing: 16px;
            margin-bottom: 38px;
            
            span {
                font-size: 54px;
            }
        }         
    }      
}