video {
	object-fit: cover;
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: none;
	z-index: -1;

	@include breakpoint(tablet) {
		display: block;
	}
}

.hero {
	background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url('../img/hero-m.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center; 
	padding: 66px 20px 0px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;

	h1 {
		color: rgba(255, 255, 255, 0.6);
		text-align: center;
		font-size: 22px;
		animation: slide-in-left .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both .4s; 
		letter-spacing: 8px;

		span {
			color: $gold;
			font-size: 42px;
		}
	}

	&.error, &.gracias {
		align-items: center;
		
		h1 {
			color: $white;
			font-size: 28px;
			letter-spacing: 0;
			text-align: left;
		}
	}  

	@include breakpoint(tablet) {
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.8) 70%);
		padding: 90px 60px 0 50%; 
		
		h1 {

			span {
				display: block;
			}
		}

		&.error, &.gracias {			
			background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url('../img/hero.jpg');
		}
	}

	@include breakpoint(laptop) {
		
		h1 {
			font-size: 28px;
			letter-spacing: 16px;

			span {
				font-size: 56px;
			}
		}

		&.error, &.gracias {
			
			h1 {
				font-size: 42px;
			}
		} 
	}

	@include breakpoint(desktop) {
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.8) 70%);

		&.error, &.gracias {			
			background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url('../img/hero-h.jpg');
		}
	}
}


@keyframes slide-in-left {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}