.contact {
    padding: 60px 20px;
    text-align: center;
    color: $grey-light;
    font-family: $font-title;
    font-size: 18px;

    h2 {
        color: $grey;
        font-size: 42px;
		letter-spacing: 8px;
        margin-bottom: 26px;
    }

    strong {
        color: $grey;
        display: block;
    }

    a {
        color: $grey-light;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    p {
        margin-bottom: 28px;

        &:last-child {
            margin: 0;
        }
    }

    hr {
        border: none;
        height: 2px;
        width: 60px;
        background: $gold;
        margin: 52px auto;
    }

    #formContactBottom {
        margin: 64px 0;

		.button {
			margin: 0 auto;
            width: 180px;
		}

        + hr {
            width: 70%;
        }
    }

    .items {
        letter-spacing: 1px;

        li {
            margin-bottom: 24px;

            &:last-child { 
                margin: 0;
            }
        }

        a {
            display: block;
        }
    }

    @include breakpoint(phablet) {
        display: grid;
        grid-template-columns: auto 60%;
        gap: 30px;
        align-items: start;
        text-align: left;

        #formContactBottom {
            margin: 0;

            + hr {
                grid-column: 1 / 3;
            } 
        }

        .items {
            grid-column: 1 / 3;
            display: flex;
            justify-content: space-between;

            li {
                text-align: center;
            }
        }
    }
    @include breakpoint(tablet) {
        padding: 90px;
        
        h2 {
            margin-bottom: 12px;
        }
    
        p {
            margin-bottom: 12px;
        }
    
        hr {
            margin: 26px auto;
        }

        #formContactBottom {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
            align-self: center;

            input, textarea {
                margin: 0;
            }

            textarea, #email {
                grid-column: 1 / 3;
            }

            .button {
                margin: 28px 0 0;
            }
        }

        .items {

            span {
                display: block;
            }
        }
    }

    @include breakpoint(desktop) {
        max-width: 1180px;
        margin: auto; 
        width: 100%;
        padding: 100px 0;

        h2 {
            font-size: 54px;
        }
    }
}