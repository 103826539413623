// Fonts
$font-title: 'Abel', sans-serif;
$font-base:	'Montserrat', sans-serif;

// Colors
$white: #fff;
$black: #000;
$blue: #0c0c25;
$blue-dark: #090914;
$gold: #ecdb97;
$grey: #4d4d4d;
$grey-light: #999999;
$grey-dark: #1a1a1a;