.header {
	background: $blue; 
	display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
	position: absolute;
	z-index: 11;
	padding: 20px;
	
	.logo-ripoll {
		width: auto;
		height: 35px;
		display: block;
	}

	&__toggle {
	    width: 27px;

	    .gracias & {
	    	display: none;
	    }
	}

	&__toggle-line {
	    display: block;
	    background: $white;
	    width: 100%;
	    height: 4px;
	    margin-bottom: 4px;
	    opacity: 1; 
	    transition: opacity 600ms ease 200ms, top 300ms ease, left 300ms ease, background 300ms ease, transform 300ms ease 450ms;    
		
		&:nth-child(1) {
		    transform-origin: center;
		}
		&:nth-child(3) {
		    transform-origin: center;
		}
	}    

	&.is-active {
	    position: fixed;
	    z-index: 12;
		
		.header__toggle-line {

			&:nth-child(1) {
			    transform: translateY(8px) rotate(45deg);
			    /*background: $white;*/
			}
			&:nth-child(2) {
			    /*background: $white;*/
			    opacity: 0;
			}
			&:nth-child(3) {
			    transform: translateY(-8px) rotate(-45deg);
			    /*background: $white;*/
			}
		}

		.button__rayen{
			display: none;
		}
	}

	&.fixed {
	    padding: 12px 18px;
	    position: fixed;
	    animation: headerFix .5s ease-out;
	}	

	@include breakpoint(tablet) {
		padding: 30px;
		
		&__toggle {
			display: none;
		}		
		
		&.fixed {
			position: absolute;
		}
		
		&.is-active {
			position: absolute;
		    z-index: 10;
		}
	}
	@include breakpoint(laptop) {
			
		.logo-ripoll {
			height: 35px;
		}
	}
}

section.menu {
    background: $white;
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 12;
    width: 100%;
    height: 100%;
    opacity: 0;  
    padding-top: 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    transition: opacity 400ms ease, left 100ms ease 600ms;
    pointer-events: none;
	
	&.is-visible {
	    left: 0;
	    opacity: 1;
	    transition: left 100ms ease, opacity 400ms ease 200ms;
		overflow: hidden;
	}
 	
	.menu-inner {
	    margin: 0;
		text-align: center;
		width:70%;
		
		.menu-list {
		    list-style: none;
		    margin: 0;
			padding: 0;
			
			li {
				font-size: 22px;
			    margin-bottom: 22px;
			    
				span {
				    line-height: 38px;
				}
				a {
				    line-height: 38px;
					color: $blue-dark;
					font-family: $font-title;
					pointer-events: auto;
				}
			}
		}
	}

	.gracias & { display: none;}

	@include breakpoint(mobilelandscape) {

		.menu-inner .menu-list {

			li {
				margin-bottom: 0;
				padding:10px 0;
			}
		}
	}

	@include breakpoint(tablet) {
		position: absolute;
		left: 0;
		top: 27px;
		z-index: 12;
		opacity: 1;
		background: none;
		padding: 0;
		padding-right: 40px;
		height: auto;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		
		.menu-inner {
			display: flex;
			width:initial;
			
			.menu-list {
			    display: flex;
			    
				li {
				    font-size: 16px;   
				    line-height: 1.6;
					margin: 0;
					padding: 0;
					
					a {
						color: $white;
					    line-height: 32px;
					    padding: 0 10px;
					    transition: color 400ms ease;
					    
					    &:hover {
					    	color: $gold; 
					    } 
					}

				}
			}
		}

		&.fixed {
		    top: 0;
			padding: 40px;
			background: $grey-dark url('../img/logo-ripoll.png') no-repeat 20px center;
			background-size: 140px;
			background-position: 40px; 
			position: fixed;
			animation: headerFix .5s ease-out;
		}
	}
	@include breakpoint(laptop) {	
		padding-right: 20px;

		.menu-inner {
			
			.menu-list {
			    
				li {

					a {
					    padding: 0 20px !important;
					}
				}
			}
		}

		&.fixed {
			background-position: 20px 22px;
			padding: 20px;
		}
	}
}

@keyframes headerFix {
  0%   { top: -100px; }
  100% { top: 0; }
}