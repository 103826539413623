*, *:before, *:after { box-sizing: border-box; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
img {
	display: block;
	width: 100%;
}
h1, h2, h3, h4, h5, h6 {
	font: 32px $font-title;
	line-height: 1.4;
}
ul, li {
	list-style: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, svg, picture {
	display: block;
}
body {
	font: 400 16px $font-base;
	color: $blue;
	line-height: 1.2;
	background: $white;
	position: relative;
}
a {
	text-decoration: none;
}
p {
	line-height: 1.4;
	margin-bottom: 12px;
	
	em {
		font-style: italic;
	}

	&:last-child {
		margin: 0;
	}
}
strong {
	font-weight: 600;
}
input, textarea {
	display: block;
	width: 100%;	
	background: transparent;
	padding: 16px;
	margin-bottom: 24px;
	border: 1px $grey solid;
	color: $grey;
	font-family: $font-title;
	font-size: 16px;
	transition: all .2s ease-in-out;
	
	&::placeholder {
		color: $grey-light;
	}	
	&::-moz-placeholder {
	  opacity: 1;
	}
	&:focus {
		background: $grey-light; 

		&::placeholder {
			color: $white;
		}
	}
	&.error {
		border-color:red;
	}
}
a.button {
	display: block;
	overflow: hidden;	
	// padding: 18px 0px;
	width: 130px;
	font-family: $font-title;
	color: $gold;
	border: 2px solid;
	border-color: $gold;
    background: transparent;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
	text-align: center;
 
	&:before, & > span {
		transition: transform 0.3s;
		transition-timing-function: ease;
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
		padding: 18px 0px;
	}    
	
	& > span {
	    display: block;
	    vertical-align: middle;
		color: $gold;
	}

	&:before {
		color: $blue-dark;
		content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $gold;
		transform: translate3d(-100%, 0, 0);
		// padding: 5px 0px;	    

	}

	&:hover {

		&:before {
		    transform: translate3d(0, 0, 0);
		}
		& > span {
			transform: translate3d(0, 100%, 0);
		}
	}
}

input.button {

	&:hover {
		background: $blue-dark;
		color: $white;
		cursor: pointer;
	}
}

iframe {
	display: block;
}

.grecaptcha-badge {
	opacity: 0;
}

.whatsapp-button {
	width: 58px;
	height: 58px;
	position: fixed;
	bottom: 30px;
	left: 30px;
	z-index: 999999; 

	.icon {
		transition: all 400ms ease;
		transform: scale(1);
	}

    &:hover .icon {
    	transform: scale(1.1);
    }		

    @include breakpoint(tablet) {
		width: 52px;
		height: 52px;
		bottom: 42px;
		left: 42px;

		.icon {
			filter: drop-shadow(2px 2px 4px rgba(0,0,0,.4));
		}
    }
}

.jquery-modal {
	z-index: 13;

	.modal {
		padding: 10px;
		border-radius: 0;
		max-width: 80%;
	}
	
	@include breakpoint(laptop) {
		
		.modal {
			max-width: 65%;
			z-index: 14;

			iframe {
				height: 70vh;
			}
		}
	}
} 